import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { IdNamePair, ProductModel, ProductTypes } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';


@State<ProductModel[]>({
  name: 'products',
  defaults: []
})
@Injectable()
export class ProductsState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([ProductsState], (state: ProductModel[]) => {
      return state.length > 0;
    });
  }

  static getNameById(id: number) {
    return createSelector([ProductsState], (state: ProductModel[]) => {
      return state.find((item: ProductModel) => item.id === id).value;
    });
  }

  static getProductsByType(productType: ProductTypes) {
    return createSelector([ProductsState], (state: ProductModel[]) => {
      return state.filter(x => x.productQuotationType === productType);
    });
  }

  constructor(injector: Injector) {
    ProductsState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<ProductsState>) {
    this.service.getProductsDropdownData(ENDPOINTS.getProductList()).subscribe((products: ProductModel[]) => {
      setState(products);
    })
  }

}
