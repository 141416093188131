import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { CustomerEntry, CustomerResult } from '../@models';
import { Injectable, Injector } from '@angular/core';
import { CustomersService } from '../@services';
import { Pagination } from '../@config';

@State<CustomerResult>({
  name: 'customers',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class CustomersState {

  private static service: CustomersService

  @Selector()
  static totalItems(state: CustomerResult): number {
    return state.totalCount || 0;
  }
  @Selector()
  static loadedItems(state: CustomerResult): number {
    return state.items?.length;
  }

  static hasAny() {
    return createSelector([CustomersState], (state: CustomerResult) => {
      return state.items?.length > 0;
    });
  }

  @Selector()
  static getAll(state: CustomerResult): CustomerEntry[] {
    return state.items;
  }

  static getCustomerById(id: number) {
    return createSelector([CustomersState], (state: CustomerResult) => {
      return state.items.find((contact: CustomerEntry) => contact.id === id);
    });
  }

  constructor(injector: Injector) {
    CustomersState.service = injector.get(CustomersService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<CustomerResult>) {
    this.service.getCustomers(0).subscribe(
      (result: CustomerResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }
  @Receiver()
  static loadNext({ setState, getState }: StateContext<CustomerResult>) {
    const state: CustomerResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getCustomers(skipCount).subscribe(
      {
        next: (result: CustomerResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }
}
