import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { OpportunitiesResult } from '../@models/index';
import { Injectable, Injector } from '@angular/core';
import { OpportunitiesService } from '../@services/index';
import { OpportunityEntry } from '../@models';
import { Pagination } from '../@config';

@State<OpportunitiesResult>({
  name: 'opportunities',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class OpportunitiesState {

  private static service: OpportunitiesService;

  @Selector()
  static totalItems(state: OpportunitiesResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static getAll(state: OpportunitiesResult): OpportunityEntry[] {
    return state.items;
  }

  @Selector()
  static loadedItems(state: OpportunitiesResult): number {
    return state.items?.length;
  }

  static hasAny() {
    return createSelector([OpportunitiesState], (state: OpportunitiesResult) => {
      return state.items?.length > 0;
    });
  }

  static getOpportunityById(id: number) {
    return createSelector([OpportunitiesState], (state: OpportunitiesResult) => {
      return state.items.find((opportunity: OpportunityEntry) => opportunity.opportunityId === id);
    });
  }

  constructor(injector: Injector) {
    OpportunitiesState.service = injector.get(OpportunitiesService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<OpportunitiesResult>) {
    this.service.getOpportunities(0).subscribe(
      (result: OpportunitiesResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }

  @Receiver()
  static loadNext({ setState, getState }: StateContext<OpportunitiesResult>) {
    const state: OpportunitiesResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getOpportunities(skipCount).subscribe(
      {
        next: (result: OpportunitiesResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

}
