import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { ColleagueEntry, ColleagueResult, ContactEntry } from '../@models';
import { Injectable, Injector } from '@angular/core';
import { ColleaguesService } from '../@services';
import { Pagination } from '../@config';

@State<ColleagueResult>({
  name: 'colleagues',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class ColleaguesState {

  private static service: ColleaguesService;

  @Selector()
  static totalItems(state: ColleagueResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static getAll(state: ColleagueResult): ColleagueEntry[] {
    return state.items;
  }

  @Selector()
  static loadedItems(state: ColleagueResult): number {
    return state.items?.length;
  }

  static hasAny() {
    return createSelector([ColleaguesState], (state: ColleagueResult) => {
      return state.items?.length > 0;
    });
  }

  static getColleagueById(id: number) {
    return createSelector([ColleaguesState], (state: ColleagueResult) => {
      return state.items.find((colleague: ColleagueEntry) => colleague.id === id);
    });
  }

  constructor(injector: Injector) {
    ColleaguesState.service = injector.get(ColleaguesService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<ColleagueResult>) {
    this.service.getColleagues(0).subscribe(
      (result: ColleagueResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }

  @Receiver()
  static loadNext({ setState, getState }: StateContext<ColleagueResult>) {
    const state: ColleagueResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getColleagues(skipCount).subscribe(
      {
        next: (result: ColleagueResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

}
