import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { LeadsService } from '../@services/index';
import { Pagination } from '../@config';
import { LeadsResult, LeadEntry } from '../@models/leads/lead-entry.model';

@State<LeadsResult>({
  name: 'leads',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class LeadsState {

  private static service: LeadsService;

  static getById(id: number) {
    return createSelector([LeadsState], (state: LeadsResult) => {
      return state.items.find((lead: LeadEntry) => lead.id === id);
    });
  }

  @Selector()
  static totalItems(state: LeadsResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static loadedItems(state: LeadsResult): number {
    return state.items?.length;
  }

  @Selector()
  static getAll(state: LeadsResult): LeadEntry[] {
    return state.items;
  }

  static hasAny() {
    return createSelector([LeadsState], (state: LeadsResult) => {
      return state.items?.length > 0;
    });
  }

  static getLeadById(id: number) {
    return createSelector([LeadsState], (state: LeadsResult) => {
      return state.items.find((lead: LeadEntry) => lead.id === id);
    });
  }

  constructor(injector: Injector) {
    LeadsState.service = injector.get(LeadsService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<LeadsResult>) {
    this.service.getLeads(0).subscribe(
      (result: LeadsResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }

  @Receiver()
  static loadNext({ setState, getState }: StateContext<LeadsResult>) {
    const state: LeadsResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getLeads(skipCount).subscribe(
      {
        next: (result: LeadsResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

}
