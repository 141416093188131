import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { QuotationsResult, QuoteEntry } from '../@models';
import { Pagination } from '../@config';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  constructor(private http: HttpClient) { }

  getQuotations(skipCount: number): Observable<QuotationsResult> {
    return this.http.get<QuotationsResult>(ENDPOINTS.getQuotations(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getAll(skipCount: number, searchText?: string): Observable<QuoteEntry[]> {
    return this.http.get<QuoteEntry[]>(ENDPOINTS.getQuotations(Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

  createOrUpdateQuote(quote: QuoteEntry): Observable<QuotationsResult> {
    console.debug('🔥 new quote payload', quote);
    return this.http.post<QuotationsResult>(ENDPOINTS.createQuote(), quote).pipe(
      map((respone: any) => respone?.result || []));
  }

}
