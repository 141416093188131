export const SideMenuItems = [
  {
    "name": "MENU.RELATIONSHIP",
    "icon": "people",
    "nav": [
      {
        "name": "MENU.COLLEAGEUS",
        "url": "/app/tabs/colleagues",
        "icon": "people-circle"
      },
      {
        "name": "MENU.CUSTOMERS",
        "url": "/app/tabs/customers",
        "icon": "person-add"
      },
      {
        "name": "MENU.CONTACTS",
        "url": "/app/tabs/contacts",
        "icon": "people"
      }
    ]
  },
  {
    "name": "MENU.MARKETING",
    "nav": [
      {
        "name": "MENU.LEADS",
        "url": "/app/tabs/leads",
        "icon": "pricetag"
      }
    ]
  },
  {
    "name": "MENU.SALES",
    "nav": [
      {
        "name": "MENU.OPPORTUNITIES",
        "url": "/app/tabs/opportunities",
        "icon": "cash"
      }
    ]
  },
  {
    "name": "MENU.SERVICES",
    "nav": [
      {
        "name": "MENU.SERVICE_CASE",
        "url": "/app/tabs/service-cases",
        "icon": "hammer"
      },
      // {
      //   "name": "MENU.CLAIM_MANAGEMENT",
      //   "url": "/app/tabs/claim-management",
      //   "icon": "hand-right"
      // }
    ]
  },
  {
    "name": "MENU.QUOTATIONS",
    "nav": [
      {
        "name": "MENU.QUOTATIONS",
        "url": "/app/tabs/quotations",
        "icon": "calculator"
      }
    ]
  }
]