import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { NotificationEntry, NotificationList } from '../@models/index';
import { Injectable, Injector } from '@angular/core';
import { NotificationService } from '../@services/index';
import { Pagination } from '../@config';
@State<NotificationList>({
  name: 'notifications',
  defaults: {
    items: [],
    totalCount: 0,
    unreadCount: 0,
    skipCount: 0
  }
})
@Injectable()
export class NotificationsState {

  private static service: NotificationService

  @Selector()
  static totalItems(state: NotificationList): number {
    return state.totalCount || 0;
  }
  @Selector()
  static loadedItems(state: NotificationList): number {
    return state.items?.length;
  }

  static hasAny() {
    return createSelector([NotificationsState], (state: NotificationList) => {
      return state.items?.length > 0;
    });
  }

  constructor(injector: Injector) {
    NotificationsState.service = injector.get(NotificationService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<NotificationsState>) {
    const startDate: string = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    const endDate: string = new Date().toISOString();
    this.service.getNotifications(startDate, endDate, 0).subscribe(
      (result: NotificationsState) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }
  @Receiver()
  static loadNext({ setState, getState }: StateContext<NotificationList>) {
    const startDate: string = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    const endDate: string = new Date().toISOString();
    const state: NotificationList = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getNotifications(startDate, endDate, skipCount).subscribe(
      {
        next: (result: NotificationList) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

  @Receiver()
  static addNotification(
    { setState, getState }: StateContext<NotificationList>,
    { payload }: EmitterAction<NotificationEntry>
  ) {
    const state = getState();
    setState({
      ...state,
      items: [...state.items, payload],
      totalCount: state.totalCount + 1,
      unreadCount: state.unreadCount + 1
    });
  }

  @Receiver()
  static markAsRead(
    { setState, getState }: StateContext<NotificationList>,
    { payload }: EmitterAction<string>
  ) {
    this.service.markAsRead(payload).subscribe((result) => {
      if (result) {
        const state = getState();
        const notification = { ...state.items.find((item) => item.id === payload), state: 1 };
        if (notification) {
          const index = state.items.findIndex((item) => item.id === payload);
          const items = [...state.items];
          items[index] = notification;
          setState({
            ...state,
            items,
            unreadCount: state.unreadCount - 1
          });
        }
      }
    });
  }

  @Receiver()
  static markAllAsRead(
    { patchState, getState }: StateContext<NotificationList>) {
    this.service.markAllAsRead().subscribe((result) => {
      if (result) {
        const state = getState();
        const notification = [...state.items].map(x=> 
          {
            return {
              ...x,
              state: 1
            } as NotificationEntry
          });
        console.debug('🔥 all notification', notification);
        patchState({
          items: notification,
          unreadCount: 0
        })
        // notification = notification.map(x => x.state = 0);
        // if (notification) {
        //   const index = state.items.findIndex((item) => item.id === payload);
        //   const items = [...state.items];
        //   items[index] = notification;
        //   setState({
        //     ...state,
        //     items,
        //     unreadCount: state.unreadCount - 1
        //   });
        // }
      }
    });
  }

}
