import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState } from '../@store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.store.selectSnapshot(AppState.token);
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        // 'Ocp-Apim-Subscription-Key': '8ce1b39de30447c484d1a01c72684dc3'
      }
    });
    // TODO: Balram - move key to somewhere safe
    // if (token) {
    //   if (this.authService.isTokenExpired()) {
    //     this.authService.logout();
    //   } else {
    //     request = request.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${token}`,
    //         'Ocp-Apim-Subscription-Key': '8ce1b39de30447c484d1a01c72684dc3'
    //       }
    //     });
    //   }
    // }
    // return next.handle(request).pipe(catchError((error:any) => {
    //   if (error instanceof HttpErrorResponse && error.status === 401) {
    //     return this.handle401Error(request, next);
    //   } else {
    //     return throwError(error);
    //   }
    // }));

    return next.handle(request);
  }


}
