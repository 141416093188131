import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { RecordsData } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class RecordsService {

  constructor(private http: HttpClient) { }

  getRecords(): Observable<RecordsData> {
    return this.http.get<RecordsData>(ENDPOINTS.getAllRecords()).pipe(
      map((respone: any) => respone?.result || []));
  }

}
