import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { IdNamePair } from '../../@models/index';
import { Injectable, Injector } from '@angular/core';
import { ContactService } from '../../@services/contacts.service';
import { DropdownService } from '../../@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';

@State<IdNamePair[]>({
  name: 'quoteStatus',
  defaults: []
})
@Injectable()
export class QuoteStatusState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([QuoteStatusState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  constructor(injector: Injector) {
    QuoteStatusState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<QuoteStatusState>) {
    this.service.getDropdownData(ENDPOINTS.getQuoteStatusList()).subscribe(
      (data: IdNamePair[]) => {
        setState(data);
      }
    );
  }

}
