import { environment } from 'src/environments/environment';

export const ENDPOINTS = {
  login: () => environment.apiUrl + '/api/TokenAuth/Authenticate',
  getUserDetails: (userId: number) => environment.apiUrl + `/api/services/app/Employee/GetUserDetails?UserId=${userId}`,
  getPubSubConnection: (userId: string) => environment.apiUrl + `/api/services/app/AzureNotification/GetPubSubConnection?userId=${userId}`,
  getTasks: (employeeId: string, maxResultCount: number, skipCount: number, searchText?: string) => environment.apiUrl + `/api/services/app/MobileTask/GetAllTaskList?SearchText=${searchText}&employeeId=${employeeId}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  createOrUpdateTask: () => environment.apiUrl + '/api/services/app/MobileTask/CreateOrUpdateTask',
  getEvents: (employeeId: string, maxResultCount: number, skipCount: number, searchText?: string) => environment.apiUrl + `/api/services/app/MobileEvent/GetAllEventList?SearchText=${searchText}&employeeId=${employeeId}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getEventById: (eventId: number) => environment.apiUrl + `/api/services/app/MobileEvent/GetEventById?eventId=${eventId}`,
  createOrUpdateEvent: () => environment.apiUrl + '/api/services/app/MobileEvent/CreateOrUpdateEvent',
  getEventTypeList: () => environment.apiUrl + '/api/services/app/Dropdown/GetEventTypeList',
  getReminders: (startDate: string, endDate: string) => environment.apiUrl + `/api/services/app/Notification/GetUserReminder?StartDate=${startDate}&EndDate=${endDate}`,
  getNotifications: (startDate: string, endDate: string, maxResultCount: number, skipCount: number) => environment.apiUrl + `/api/services/app/Notification/GetUserNotifications?StartDate=${startDate}&EndDate=${endDate}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  setNotificationAsRead: () => environment.apiUrl + '/api/services/app/Notification/SetNotificationAsRead',
  setAllNotificationsAsRead: () => environment.apiUrl + '/api/services/app/Notification/SetAllNotificationsAsRead',
  getContacts: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileContact/GetAllContactList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getContactById: (id: number) => environment.apiUrl + `/api/services/app/MobileContact/GetContactById?contactId=${id}`,
  getCustomers: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileCustomer/GetAllCustomerList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getColleagues: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileEmployee/GetAllEmployeeList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getLeads: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileLead/GetAllLeadList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getleadById: (id: number) => environment.apiUrl + `/api/services/app/MobileLead/GetLeadById?leadId=${id}`,
  getOpportunities: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileOpportunity/GetOpportunityList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getOpportunityById: (id: number) => environment.apiUrl + `/api/services/app/MobileOpportunity/GetOpportunityById?opportunityId=${id}`,
  createOrUpdateOpportunity: () => environment.apiUrl + `/api/services/app/MobileOpportunity/CreateOrUpdateOpportunity`,
  createOrUpdateLead: () => environment.apiUrl + `/api/services/app/MobileLead/CreateOrUpdateLead`,
  getServiceCases: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileServiceCase/GetAllServiceCaseList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getQuotations: (maxResultCount: number, skipCount: number, searchText: string) => environment.apiUrl + `/api/services/app/MobileQuotation/GetAllQuoteList?SearchText=${searchText}&MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`,
  getQuoteStatusList: () => environment.apiUrl + `/api/services/app/Dropdown/GetQuotationStatusList`,
  createQuote: () => environment.apiUrl + `/api/services/app/MobileQuotation/CreateOrUpdateQuote`,
  getTaskStatus: () => environment.apiUrl + `/api/services/app/Dropdown/GetTaskStatusList`,
  getOpportunityPhaseList: () => environment.apiUrl + `/api/services/app/Dropdown/GetOpportunityPhaseList`,
  getOpportunityProductStatusList: () => environment.apiUrl + `/api/services/app/Dropdown/GetOpportunityProductStatusList?opportunityPhaseId=1`,
  getProductsFamilyList: () => environment.apiUrl + `/api/services/app/Dropdown/ListProductsFamily`,
  getProductsListBasedOnProductFamily: (productFamilyId: number) => environment.apiUrl + `/api/services/app/Dropdown/GetProductsListBasedOnProductFamily?productFamilyId=${productFamilyId}`,
  ListTradeLanes: () => environment.apiUrl + `/api/services/app/Dropdown/ListTradeLanes`,
  getListTradeLanesBasedOnProductFamily: (productFamilyId: number) => environment.apiUrl + `/api/services/app/Dropdown/ListTradeLanesBasedOnProductFamily?productFamily=${productFamilyId}`,
  getCompetitorList: () => environment.apiUrl + `/api/services/app/Dropdown/GetCompetitorList`,
  getTaskTypes: () => environment.apiUrl + `/api/services/app/Dropdown/GetTaskType`,
  getTaskTopics: () => environment.apiUrl + `/api/services/app/Dropdown/GetTaskTopicList`,
  getAllRecords: () => environment.apiUrl + `/api/services/app/ReAssignEmployee/GetAllRecords?EmployeeId=0`,
  getProductList: () => environment.apiUrl + `/api/services/app/Dropdown/GetProductsList`,
  getIndustrySegmentList: () => environment.apiUrl + `/api/services/app/Dropdown/GetIndustrySegmentList`,
  getLeadStatusList: () => environment.apiUrl + `/api/services/app/Dropdown/GetLeadStatusList`,
  getLeadScoringList: () => environment.apiUrl + `/api/services/app/Dropdown/GetLeadScoringList`,
  getLeadSourceList: () => environment.apiUrl + `/api/services/app/Dropdown/GetLeadSourceList`,
  getCustomerClusterList: () => environment.apiUrl + `/api/services/app/CustomerClusterses/GetAll`,
  getServiceCaseTypes: () => environment.apiUrl + `/api/services/app/Dropdown/GetServiceCaseTypeList`,
  createOrUpdateServiceCase: () => environment.apiUrl + '/api/services/app/MobileServiceCase/CreateOrUpdateServiceCase',
  getServiceCaseComments: (serviceCaseId: number) => environment.apiUrl + `/api/services/app/MobileServiceCase/GetComments?serviceCaseId=${serviceCaseId}`,
  createServiceCaseComment: () => environment.apiUrl + `/api/services/app/MobileServiceCase/Comment`,
  getTenantId: () => environment.apiUrl + '/api/services/app/Session/GetTenantId',
  getCountryList: () => environment.apiUrl + '/api/services/app/Dropdown/GetCountryList',
  getPostalCodeList: (countryId: number) => environment.apiUrl + `/api/services/app/Dropdown/GetPostalCodeList?countryId=${countryId}`,
  getPostalCodeForAutoComplete: (countryId: number, count: number, searchTerm?: string) => environment.apiUrl + `/api/services/app/Dropdown/GetPostalCodeForAutoComplete?countryId=${countryId}&searchTerm=${searchTerm}&count=${count}`,
  getIncotermList: () => environment.apiUrl + '/api/services/app/Dropdown/GetIncotermList',
  getPackageTypeList: (tenantId: number, productId: number) => environment.apiUrl + `/api/services/app/Dropdown/GetQuotationPackageTypeList?productId=${productId}&tenantId=${tenantId}`,
  getLogedInUserInfo: () => environment.apiUrl + `/api/services/app/Session/GetCurrentLoginInformations`,
  getProductsByType: (tenantId: number, productType: number) => environment.apiUrl + `/api/services/app/Dropdown/GetProductsListForCIP?tenantId=${tenantId}&productType=${productType}`
}