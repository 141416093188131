import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { ContactEntry, ContactResult } from '../@models';
import { Injectable, Injector } from '@angular/core';
import { ContactService } from '../@services/contacts.service';
import { Pagination } from '../@config';

@State<ContactResult>({
  name: 'contacts',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class ContactsState {

  private static contactService: ContactService

  @Selector()
  static totalItems(state: ContactResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static getAll(state: ContactResult): ContactEntry[] {
    return state.items;
  }

  @Selector()
  static loadedItems(state: ContactResult): number {
    return state.items?.length;
  }

  static getContactById(id: number) {
    return createSelector([ContactsState], (state: ContactResult) => {
      return state.items.find((contact: ContactEntry) => contact.id === id);
    });
  }

  static hasAny() {
    return createSelector([ContactsState], (state: ContactResult) => {
      return state.items?.length > 0;
    });
  }

  constructor(injector: Injector) {
    ContactsState.contactService = injector.get(ContactService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<ContactResult>) {
    this.contactService.getContacts(0).subscribe(
      (result: ContactResult) => {
        const newState: ContactResult = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }

  @Receiver()
  static loadNext({ setState, getState }: StateContext<ContactResult>) {
    const state: ContactResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.contactService.getContacts(skipCount).subscribe(
      {
        next: (result: ContactResult) => {
          const tasks: ContactEntry[] = [...state.items, ...result.items];
          const newState: ContactResult = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

}
