import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Emittable, Emitter, EmitterService } from '@ngxs-labs/emitter';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppState, AppStateModel } from '../@store';
import { AuthRequest } from '../@models';
import { ENDPOINTS } from '../@config/endpoints';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private emitter: EmitterService, private http: HttpClient, private router: Router) { }

    setTenantId(): void {
        this.http.get(ENDPOINTS.getTenantId()).pipe(
            tap((response: any) => {
                console.debug('🔥 tenantId', response.result);
                this.emitter.action(AppState.setTenantId).emit(response.result as any);
            })
        ).subscribe();
    }
}
