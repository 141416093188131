import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { IdNamePair } from '../../@models/index';
import { Injectable, Injector } from '@angular/core';
import { ContactService } from '../../@services/contacts.service';
import { DropdownService } from '../../@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';

@State<IdNamePair[]>({
  name: 'competitors',
  defaults: []
})
@Injectable()
export class CompetitorsState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([CompetitorsState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  static getNameById(id: number) {
    return createSelector([CompetitorsState], (state: IdNamePair[]) => {
      return state.find((item: IdNamePair) => item.id === id).name;
    });
  }

  constructor(injector: Injector) {
    CompetitorsState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<CompetitorsState>) {
    this.service.getDropdownData(ENDPOINTS.getCompetitorList()).subscribe(
      (data: IdNamePair[]) => {
        setState(data);
      }
    );
  }

}
