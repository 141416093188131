import { Selector, State, StateContext } from '@ngxs/store';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { EventEntry, ReminderEntry } from '../@models/index';
import { Injectable, Injector } from '@angular/core';
import { FeedService } from '../@services/index';

@State<ReminderEntry[]>({
  name: 'reminders',
  defaults: []
})
@Injectable()
export class RemindersState {

  private static feedService: FeedService

  @Selector()
  static getAll(state: ReminderEntry[]): ReminderEntry[] {
    return state || [];
  }

  constructor(injector: Injector) {
    RemindersState.feedService = injector.get(FeedService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<RemindersState>) {
    const startDate: string = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString();
    const endDate: string = new Date().toISOString();

    this.feedService.getReminders(startDate, endDate).subscribe(
      (reminders: ReminderEntry[]) => {
        setState(reminders);
      }
    );
  }

}
