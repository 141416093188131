import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { IdNamePair, ProductTypes } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';


@State<IdNamePair[]>({
  name: 'tradeLanes',
  defaults: []
})
@Injectable()
export class TradeLanesState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([TradeLanesState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  static getNameById(id: number) {
    return createSelector([TradeLanesState], (state: IdNamePair[]) => {
      return state.find((item: IdNamePair) => item.id === id).name;
    });
  }


  constructor(injector: Injector) {
    TradeLanesState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<TradeLanesState>) {
    this.service.getDropdownDataByPost(ENDPOINTS.ListTradeLanes()).subscribe((products: IdNamePair[]) => {
      setState(products);
    })
  }

}
