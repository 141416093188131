import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../@services';

export interface CurrentUserModel {
  id?: 0,
  name?: string,
  surname?: string,
  phoneNumber?: string,
  emailAddress?: string,
  location?: string,
  roleName?: string,
  licenseType?: number,
  titleId?: number,
  titleName?: string,
  gender?: number,
  picture?: string
}

@State<CurrentUserModel>({
  name: 'currentUser',
  defaults: {}
})
@Injectable()
export class CurrentUserState {
  private static authService: AuthService


  @Selector()
  static user(state: CurrentUserModel): CurrentUserModel {
    return state;
  }

  constructor(injector: Injector) {
    CurrentUserState.authService = injector.get(AuthService);
  }

  @Receiver()
  static setCurrentUser({ setState }: StateContext<CurrentUserModel>) {
    const userId =
      this.authService.getCurrentUser().subscribe((userDetails: CurrentUserModel) => {
        console.debug('🔥 user details', userDetails);
        setState(userDetails);
      });
  }
}
