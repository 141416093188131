import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { ContactEntry, ContactResult, ContactSearchModel } from '../@models';
import { Pagination } from '../@config';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }
z
  getContacts(skipCount: number): Observable<ContactResult> {
    return this.http.get<ContactResult>(ENDPOINTS.getContacts(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getAll(maxResultCount: number, skipCount: number,  searchText?: string): Observable<ContactEntry[]> {
    return this.http.get<ContactEntry[]>(ENDPOINTS.getContacts(maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }
}
