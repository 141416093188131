import { Selector, State, StateContext } from '@ngxs/store';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';

export interface AppInfoModel {
  tenancyName?: string;
  userNameOrEmailAddress?: string;
  darkMode?: boolean;
  version?: string;
}

@State<AppInfoModel>({
  name: 'appInfo',
  defaults: { darkMode: false }
})
@Injectable()
export class AppInfoState {

  @Selector()
  static darkMode(state: AppInfoModel): boolean {
    return state.darkMode;
  }

  @Receiver()
  static setAppInfo({ getState, patchState }: StateContext<AppInfoModel>,
    { payload }: EmitterAction<AppInfoModel>) {
    const state = { ...getState(), ...payload }
    patchState(state);
  }

  @Receiver()
  static setTheme(
    { patchState }: StateContext<AppInfoModel>,
    { payload }: EmitterAction<boolean>) {
    patchState({ darkMode: payload });
  }
}
