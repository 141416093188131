import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from '@ngxs-labs/emitter';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { AppInfoState, AppState, AppStateModel } from '../@store';
import { AuthRequest } from '../@models';
import { ENDPOINTS } from '../@config/endpoints';
import { CurrentUserModel } from '../@store/current-user.state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn = new BehaviorSubject<boolean>(false);
  hideFooter = new BehaviorSubject<boolean>(false);

  constructor(private store: Store,
    private http: HttpClient,
    private emitterSerivce: EmitterService,
    private router: Router) { }

  login(user: AuthRequest): Observable<any> {
    return this.http.post<any>(ENDPOINTS.login(), user).pipe(
      tap((response: any) => {
        if (response.result) {
          const payload: AppStateModel = {
            userId: response.result?.userId,
            token: response.result?.accessToken,
          };
          this.emitterSerivce.action(AppState.setAuthDetails).emit(payload as any);
        }
      }),
      catchError(this.handleError),
    );
  }

  getCurrentUser(): Observable<CurrentUserModel> {
    const userId = this.store.selectSnapshot(AppState.userId);
    console.debug('🔥 getting details for user id', userId);
    return this.http.get<CurrentUserModel>(ENDPOINTS.getUserDetails(+userId)).pipe(
      map((response: any) => response.result),
      catchError(this.handleError),
    );
  }

  isTokenExpired() {
    const token = this.store.selectSnapshot(AppState.token);
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expiry * 1000 > Date.now();
  }

  logout(): void {
    this.store.dispatch(
      new StateClear(AppInfoState)
    );
    this.router.navigate(['/login']);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
