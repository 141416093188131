import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { ColleagueEntry, ColleagueResult } from '../@models';
import { Pagination } from '../@config';

@Injectable({
  providedIn: 'root'
})
export class ColleaguesService {

  constructor(private http: HttpClient) { }

  getColleagues(skipCount: number): Observable<ColleagueResult> {
    return this.http.get<ColleagueResult>(ENDPOINTS.getColleagues(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getAll(maxResultCount: number, skipCount: number, searchText?: string): Observable<ColleagueEntry[]> {
    return this.http.get<ColleagueEntry[]>(ENDPOINTS.getColleagues(maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }
}
