import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../@config';
import { ENDPOINTS } from '../@config/endpoints';
import { EventEntry, EventResult, ReminderEntry, TaskEntry, TaskResult } from '../@models';
import { AppState } from '../@store';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor(private store: Store, private http: HttpClient) { }

  getTasks(skipCount: number): Observable<TaskResult> {
    const userId = this.store.selectSnapshot(AppState.userId);
    return this.http.get<TaskResult>(ENDPOINTS.getTasks(userId, Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getEvents(skipCount: number): Observable<EventResult> {
    const userId = this.store.selectSnapshot(AppState.userId);
    return this.http.get<EventResult>(ENDPOINTS.getEvents('0', Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getReminders(startDate: string, endDate: string): Observable<ReminderEntry[]> {
    return this.http.get<ReminderEntry[]>(ENDPOINTS.getReminders(startDate, endDate)).pipe(
      map((respone: any) => respone?.result || []));
  }
}
