import { State, StateContext, Selector } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { OpportunityProductDto} from '../@models';
import { LeadProduct } from '../@models/leads/lead-response-entry.model';
import { NewLeadStateEntry } from '../@models/leads/new-lead-state-entry.model';

@State<NewLeadStateEntry>({
  name: 'newLead',
  defaults: {}
})
@Injectable()
export class NewLeadState {

  @Selector()
  static products(state: NewLeadStateEntry): LeadProduct[] {
    return state?.leadProducts;
  }

  constructor(injector: Injector) {
  }

  @Receiver()
  static reset({ setState }: StateContext<NewLeadStateEntry>) {
    setState({})
  }

  // @Receiver()
  // static setQuoteForEdit({ setState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<NewLeadStateEntry>) {
  //   setState(payload);
  // }
  @Receiver()
  static set({ setState }: StateContext<NewLeadStateEntry>,
    { payload }: EmitterAction<NewLeadStateEntry>) {
    setState({ ...payload });
  }

  @Receiver()
  static update({ patchState }: StateContext<NewLeadStateEntry>,
    { payload }: EmitterAction<NewLeadStateEntry>) {
    patchState({ ...payload });
  }



  @Receiver()
  static addProduct({ patchState, getState }: StateContext<NewLeadStateEntry>,
    { payload }: EmitterAction<OpportunityProductDto>) {
    const productList: LeadProduct[] = [...getState().leadProducts || []];
    let maxId = 0;
    if (productList.length > 0) {
      maxId = productList.reduce(
        (max, cargo) => (cargo.uniqueId > max ? cargo.uniqueId : max),
        productList[0].uniqueId
      );
    }
    const newCargo: LeadProduct = { ...payload, uniqueId: maxId + 1 }
    productList.push(newCargo);
    patchState({ leadProducts: productList });
  }

  // @Receiver()
  // static editCargo({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<CargoShipment>) {
  //   const cargoShipments = getState().quoteProduct.cargoShipments.filter((entry: CargoShipment) => {
  //     if (entry.id !== payload.id) return entry;
  //   })
  //   cargoShipments.push(payload)
  //   const quoteProduct = { ...getState().quoteProduct, cargoShipments };
  //   patchState({ quoteProduct });
  // }

  @Receiver()
  static removeProduct({ patchState, getState }: StateContext<NewLeadStateEntry>,
    { payload }: EmitterAction<number>) {
    const products = getState().leadProducts.filter((entry: OpportunityProductDto) => {
      if (entry.uniqueId !== payload) return entry;
    })
    patchState({ leadProducts: products });
  }

  // @Receiver()
  // static setPickupDate({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<Date>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     pickDateTime: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setDeliveryDate({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<Date>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     deliveryDateTime: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsDangerousGoods({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isDangerousGoodCargo: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsNotification({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isNotification: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsAdditionalUnloadingPoint({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isAdditionalUnloadingPoint: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setAdditionalUnloadingPointNumber({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<number>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     additionalUnloadingPointNumber: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsFloorBasementDelivery({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isFloorBasementDelivery: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsHCDGGood({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isHCDGGood: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsLenghtOverThreeMeter({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isLenghtOverThreeMeter: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsCashonDelivery({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isCashonDelivery: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setCashonDeliveryTotalCharge({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<number>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     cashonDeliveryTotalCharge: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsOtherServices({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isAdditionalServices: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setOtherServices({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<string>) {

  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     additionalServiceDetails: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setColleague({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<ColleagueEntry>) {

  //   patchState({
  //     colleagueId: payload.id,
  //     colleagueName: payload?.lastName + ' ' + payload?.firstName,
  //     colleaguePictureUrl: payload?.picture
  //   });
  // }

  // @Receiver()
  // static setAdditionalRemarks({ patchState, getState }: StateContext<NewLeadStateEntry>,
  //   { payload }: EmitterAction<string>) {
  //   patchState({
  //     comment: payload
  //   });
  // }

}
