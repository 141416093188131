import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { IdNamePair } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';

@State<IdNamePair[]>({
  name: 'taskTopic',
  defaults: []
})
@Injectable()
export class TaskTopicState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([TaskTopicState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  constructor(injector: Injector) {
    TaskTopicState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<TaskTopicState>) {
    this.service.getDropdownData(ENDPOINTS.getTaskTopics()).subscribe(
      (data: IdNamePair[]) => {
        setState(data);
      }
    );
  }

}
