import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { Pagination } from '../@config';
import { LeadResponseEntry } from '../@models/leads/lead-response-entry.model';
import { LeadsResult, LeadEntry } from '../@models/leads/lead-entry.model';
import { NewLeadStateEntry } from '../@models/leads/new-lead-state-entry.model';
@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  constructor(private http: HttpClient) { }

  getLeads(skipCount: number): Observable<LeadsResult> {
    return this.http.get<LeadsResult>(ENDPOINTS.getLeads(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getById(id: number): Observable<LeadResponseEntry> {
    return this.http.get<LeadResponseEntry>(ENDPOINTS.getleadById(id)).pipe(
      map((respone: any) => respone?.result));
  }

  getAll(skipCount: number, searchText?: string): Observable<LeadEntry[]> {
    return this.http.get<LeadEntry[]>(ENDPOINTS.getLeads(Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

  createOrUpdateLead(lead: NewLeadStateEntry): Observable<any> {
    console.debug('🔥 new lead payload', lead);
    return this.http.post(ENDPOINTS.createOrUpdateLead(), lead).pipe(
      map((respone: any) => respone?.result || []));
  }
}
