import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { EventEntry, EventResult } from '../@models';
import { FeedService } from '../@services';
import { Injectable, Injector } from '@angular/core';
import { Pagination } from '../@config';

@State<EventResult>({
  name: 'events',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class EventsState {

  private static feedService: FeedService

  @Selector()
  static totalItems(state: EventResult): number {
    return state.totalCount || 0;
  }

  @Selector()
  static loadedItems(state: EventResult): number {
    return state.items?.length;
  }

  static getEventById(id: number) {
    return createSelector([EventsState], (state: EventResult) => {
      return state.items.find((event: EventEntry) => event.id === id);
    });
  }

  static hasAny() {
    return createSelector([EventsState], (state: EventResult) => {
      return state.items?.length > 0;
    });
  }

  @Selector()
  static getAll(state: EventResult): EventEntry[] {
    return state.items || [];
  }

  constructor(injector: Injector) {
    EventsState.feedService = injector.get(FeedService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<EventResult>) {
    this.feedService.getEvents(0).subscribe(
      (result: EventResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }

  @Receiver()
  static loadNext({ setState, getState }: StateContext<EventResult>) {
    const state: EventResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.feedService.getEvents(skipCount).subscribe(
      {
        next: (result: EventResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }

}
