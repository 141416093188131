import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS, Pagination } from '../@config';
import { EventEntry } from '../@models';
import { AppState } from '../@store';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private http: HttpClient, private store: Store) { }

  createOrUpdateEvent(payload: EventEntry): Observable<any> {
    return this.http.post(ENDPOINTS.createOrUpdateEvent(), payload);
  }

  getAll(skipCount: number, searchText?: string): Observable<EventEntry[]> {
    const userId = this.store.selectSnapshot(AppState.userId);
    return this.http.get<EventEntry[]>(ENDPOINTS.getEvents(userId, Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

}
