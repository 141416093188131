import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { CustomerEntry, CustomerResult } from '../@models';
import { Pagination } from '../@config';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  getCustomers(skipCount: number): Observable<CustomerResult> {
    return this.http.get<CustomerResult>(ENDPOINTS.getCustomers(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getAll(skipCount: number, searchText?: string): Observable<CustomerEntry[]> {
    return this.http.get<CustomerResult>(ENDPOINTS.getCustomers(Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }
}
