import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { RecordEntry, RecordsData } from '../@models';
import { Injectable, Injector } from '@angular/core';
import { RecordsService } from '../@services';

@State<RecordsData>({
  name: 'records',
  defaults: {
    totalCount: 0,
    items: [],
  }
})
@Injectable()
export class RecordsState {

  private static service: RecordsService;

  @Selector()
  static getAll(state: RecordsData): RecordEntry[] {
    return state.items || [];
  }

  static hasAny() {
    return createSelector([RecordsState], (state: RecordsData) => {
      return state.totalCount > 0;
    });
  }

  static getRecordById(id: number) {
    return createSelector([RecordsState], (state: RecordsData) => {
      return state.items.find((entry: RecordEntry) => entry.id === id);
    });
  }

  constructor(injector: Injector) {
    RecordsState.service = injector.get(RecordsService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<RecordsState>) {
    this.service.getRecords().subscribe(
      (data: RecordsData) => {
        setState(data);
      }
    );
  }

}
