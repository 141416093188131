import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { EmitterService, Emitter, Emittable } from '@ngxs-labs/emitter';
import { Store } from '@ngxs/store';
import { webSocket } from 'rxjs/webSocket';
import { ENDPOINTS } from '../@config/endpoints';
import { NotificationEntry } from '../@models';
import { AppState, NotificationsState } from '../@store';

export const enum NotificationType {
  TaskAssigned = 'App.TaskAssigned',
  EventAssigned = 'App.EventAssigned'
}
@Injectable({
  providedIn: 'root'
})
export class AzureNotificationService {

  wwsUri: any;

  constructor(private http: HttpClient, private store: Store, public toastController: ToastController, private emitter: EmitterService, private router: Router) { }

  connect(): void {
    const userId = this.store.selectSnapshot(AppState.userId);
    console.debug('👉🏽 Connecting to notification service...',);
    this.http.get(ENDPOINTS.getPubSubConnection(userId)).subscribe(
      (wwsUri: any) => {
        console.debug('👉🏽 Notification service connected!');
        this.wwsUri = wwsUri;
        const subject = webSocket(this.wwsUri.result);
        subject.subscribe(
          (data: any) => {
            console.debug('👉🏽 Notification recevied', data);
            this.handleNotifications(data);
          },
          (err) => console.debug('👉🏽 Notification error', err),
          () => console.debug('👉🏽 Notification message finished')
        );
      },
      (error) => console.log('Notification connection error: ', error),
      () => console.log('Notification Service Completed')
    );
  }

  private async handleNotifications(message: NotificationEntry) {
    console.debug('👉🏽 New Notification received', message);

    this.emitter.action(NotificationsState.addNotification).emit({ message } as any);
    this.emitter.action(NotificationsState.loadFirst).emit();

    const notificationName = message.notification.notificationName.split('.')[1];

    const toast = await this.toastController.create({
      header: 'New Notification!',
      message: notificationName,
      icon: 'information-circle',
      position: 'top',
      color: 'primary',
      buttons: [
        {
          side: 'end',
          text: 'Open',
          handler: () => {
            this.openNotification(message);
          }
        },
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  public openNotification(message: NotificationEntry): void {
    let targetPageUrl = '/app/tabs/notifications';
    switch (message.notification.notificationName) {
      case NotificationType.TaskAssigned:
        targetPageUrl = '/app/tabs/tasks'
        break;
      case NotificationType.EventAssigned:
        targetPageUrl = '/app/tabs/events'
        break;
      default:
        break;
    }
    this.router.navigate([targetPageUrl]);

  }
}
