import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { CreateOpportunityPayload, OpportunitiesResult, OpportunityEntry, OpportunityStateEntry } from '../@models';
import { Pagination } from '../@config';
import { OpportunityResponseEntry } from '../@models/opportunity/opportunity-response-entry.model';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {

  constructor(private http: HttpClient) { }

  getOpportunities(skipCount: number): Observable<OpportunitiesResult> {
    return this.http.get<OpportunitiesResult>(ENDPOINTS.getOpportunities(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getById(id: number): Observable<OpportunityResponseEntry> {
    return this.http.get<OpportunityResponseEntry>(ENDPOINTS.getOpportunityById(id)).pipe(
      map((respone: any) => respone?.result));
  }

  getAll(skipCount: number, searchText?: string): Observable<OpportunityEntry[]> {
    return this.http.get<OpportunityEntry[]>(ENDPOINTS.getOpportunities(Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

  createOrUpdateOpportunity(opportunity: CreateOpportunityPayload): Observable<any> {
    console.debug('🔥 new opportunity payload', opportunity);
    return this.http.post(ENDPOINTS.createOrUpdateOpportunity(), opportunity).pipe(
      map((respone: any) => respone?.result || []));
  }

}
