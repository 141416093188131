import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../@config';
import { ENDPOINTS } from '../@config/endpoints';
import { CityAndPostalCode, IdNamePair, ProductModel } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor(private http: HttpClient) { }

  getDropdownData(url: string): Observable<IdNamePair[]> {
    return this.http.get<IdNamePair[]>(url).pipe(
      map((respone: any) => respone?.result || []),
      map((data: IdNamePair[]) => {
        return data.map((data: IdNamePair) => {
          return {
            id: data.id,
            name: data.name,
            value: data.value
          };
        });
      })
    );
  }

  getDropdownDataByPost(url: string): Observable<IdNamePair[]> {
    return this.http.post<IdNamePair[]>(url,{}).pipe(
      map((respone: any) => respone?.result || []),
      map((data: IdNamePair[]) => {
        return data.map((data: IdNamePair) => {
          return {
            id: data.id,
            name: data.name,
            value: data.value
          };
        });
      })
    );
  }

  getProductsDropdownData(url: string): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(url).pipe(
      map((respone: any) => respone?.result || []),
      map((data: ProductModel[]) => {
        return data.map((data: ProductModel) => {
          return {
            id: data.id,
            name: data.name,
            value: data.value,
            productQuotationType: data?.productQuotationType            
          };
        });
      })
    );
  }

  getPostalCodeForAutoComplete(countryId: number, searchTerm?:string): Observable<CityAndPostalCode[]> {
    return this.http.get<CityAndPostalCode[]>(ENDPOINTS.getPostalCodeForAutoComplete(countryId, Pagination.maxResultCount, searchTerm || '')).pipe(
      map((respone: any) => respone?.result || []),
      map((data: CityAndPostalCode[]) => {
        return data.map((data: CityAndPostalCode) => {
          return {
            cityId: data.cityId,
            cityName: data.cityName,
            postalCode: data.postalCode,
            location: data.location,
            id: data.id
          };
        });
      })
    );
  }

  getDropdownDataForCityAndPostalcode(url: string): Observable<CityAndPostalCode[]> {
    return this.http.get<CityAndPostalCode[]>(url).pipe(
      map((respone: any) => respone?.result || []),
      map((data: CityAndPostalCode[]) => {
        return data.map((data: CityAndPostalCode) => {
          return {
            cityId: data.cityId,
            cityName: data.cityName,
            postalCode: data.postalCode,
            location: data.location,
            id: data.id
          };
        });
      })
    );
  }

  getIdNameList(url: string): Observable<IdNamePair[]> {
    return this.http.get<IdNamePair[]>(url).pipe(
      map((respone: any) => respone?.result.items || []),
      map((data: IdNamePair[]) => {
        return data.map((data: IdNamePair) => {
          return {
            id: data.id,
            name: data.name
          };
        });
      })
    );
  }
}
