import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { IdNamePair } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';

@State<IdNamePair[]>({
  name: 'taskType',
  defaults: []
})
@Injectable()
export class TaskTypeState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([TaskTypeState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  constructor(injector: Injector) {
    TaskTypeState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<TaskTypeState>) {
    this.service.getDropdownData(ENDPOINTS.getTaskTypes()).subscribe(
      (data: IdNamePair[]) => {
        setState(data);
      }
    );
  }

}
