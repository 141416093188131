import { State, StateContext, Selector } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { ContactEntry, OpportunityProductDto, OpportunityStateDto, OpportunityStateEntry } from '../@models';


@State<OpportunityStateEntry>({
  name: 'newOpportunity',
  defaults: {}
})
@Injectable()
export class NewOpportunityState {

  @Selector()
  static products(state: OpportunityStateEntry): OpportunityProductDto[] {
    return state?.opportunityProductDto;
  }

  constructor(injector: Injector) {
  }

  @Receiver()
  static reset({ setState }: StateContext<OpportunityStateEntry>) {
    setState({})
  }

  @Receiver()
  static setQuoteForEdit({ setState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityStateEntry>) {
    setState(payload);
  }

  @Receiver()
  static set({ setState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityStateEntry>) {
    setState({ ...payload });
  }

  @Receiver()
  static update({ getState, patchState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityStateEntry>) {
    const state = getState();
    patchState({ ...payload });
  }

  @Receiver()
  static setDefaultDates({ getState, patchState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<string>) {

    const state: OpportunityStateEntry = getState();
    const opportunityDto: OpportunityStateDto = {
      ...state.opportunityDto,
      businessStartDate: new Date().toISOString(),
      businessRenewalDate: new Date().toISOString(),
      estimatedCloseDate: new Date().toISOString(),
      phaseDateSince: new Date().toISOString(),
    }
    patchState({ ...state, opportunityDto });
  }

  @Receiver()
  static addProduct({ patchState, getState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityProductDto>) {
    const productList: OpportunityProductDto[] = [...getState().opportunityProductDto || []];
    let maxId = 0;
    if (productList.length > 0) {
      maxId = productList.reduce(
        (max, cargo) => (cargo.uniqueId > max ? cargo.uniqueId : max),
        productList[0].uniqueId
      );
    }
    const newCargo: OpportunityProductDto = { ...payload, uniqueId: maxId + 1 }
    productList.push(newCargo);
    patchState({ opportunityProductDto: productList });
  }

  @Receiver()
  static editProduct({ patchState, getState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityProductDto>) {
      debugger
    const productList = getState().opportunityProductDto.filter((entry: OpportunityProductDto) => {
      if (entry.id !== payload.id) return entry;
    })
    productList.push(payload);
    patchState({ opportunityProductDto: productList });
  }

  @Receiver()
  static removeProduct({ patchState, getState }: StateContext<OpportunityStateEntry>,
    { payload }: EmitterAction<OpportunityProductDto>) {
    const products = getState().opportunityProductDto.filter((entry: OpportunityProductDto) => {
      if (entry.uniqueId !== payload.uniqueId) return entry;
    })
    patchState({ opportunityProductDto: products });
  }

  // @Receiver()
  // static setPickupDate({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<Date>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     pickDateTime: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setDeliveryDate({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<Date>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     deliveryDateTime: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsDangerousGoods({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isDangerousGoodCargo: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsNotification({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isNotification: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsAdditionalUnloadingPoint({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isAdditionalUnloadingPoint: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setAdditionalUnloadingPointNumber({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<number>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     additionalUnloadingPointNumber: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsFloorBasementDelivery({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isFloorBasementDelivery: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsHCDGGood({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isHCDGGood: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsLenghtOverThreeMeter({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isLenghtOverThreeMeter: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsCashonDelivery({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isCashonDelivery: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setCashonDeliveryTotalCharge({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<number>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     cashonDeliveryTotalCharge: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setIsOtherServices({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<boolean>) {
  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     isAdditionalServices: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setOtherServices({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<string>) {

  //   const aditionalService: AditionalService = {
  //     ...getState().quoteProduct.aditionalService,
  //     additionalServiceDetails: payload
  //   }
  //   const quoteProduct = { ...getState().quoteProduct, aditionalService };
  //   patchState({ quoteProduct });
  // }

  // @Receiver()
  // static setColleague({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<ColleagueEntry>) {

  //   patchState({
  //     colleagueId: payload.id,
  //     colleagueName: payload?.lastName + ' ' + payload?.firstName,
  //     colleaguePictureUrl: payload?.picture
  //   });
  // }

  // @Receiver()
  // static setAdditionalRemarks({ patchState, getState }: StateContext<OpportunityStateEntry>,
  //   { payload }: EmitterAction<string>) {
  //   patchState({
  //     comment: payload
  //   });
  // }

}
