import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { ServiceCaseResult } from '../@models/index';
import { Injectable, Injector } from '@angular/core';
import { ServiceCasesService } from '../@services/index';
import { ServiceCaseEntry } from '../@models';
import { Pagination } from '../@config';

@State<ServiceCaseResult>({
  name: 'serviceCases',
  defaults: {
    totalCount: 0,
    items: [],
    skipCount: 0
  }
})
@Injectable()
export class ServiceCasesState {

  private static service: ServiceCasesService;

  @Selector()
  static totalItems(state: ServiceCaseResult): number {
    return state.totalCount || 0;
  }
  @Selector()
  static loadedItems(state: ServiceCaseResult): number {
    return state.items?.length;
  }

  @Selector()
  static getAll(state: ServiceCaseResult): ServiceCaseEntry[] {
    return state.items;
  }

  static hasAny() {
    return createSelector([ServiceCasesState], (state: ServiceCaseResult) => {
      return state.items?.length > 0;
    });
  }

  static getById(id: number) {
    return createSelector([ServiceCasesState], (state: ServiceCaseResult) => {
      return state.items.find((serviceCase: ServiceCaseEntry) => serviceCase.id === id);
    });
  }

  constructor(injector: Injector) {
    ServiceCasesState.service = injector.get(ServiceCasesService);
  }

  @Receiver()
  static loadFirst({ setState }: StateContext<ServiceCaseResult>) {
    this.service.getServiceCases(0).subscribe(
      (result: ServiceCaseResult) => {
        const newState = { ...result, skipCount: 0 };
        setState(newState);
      }
    );
  }
  @Receiver()
  static loadNext({ setState, getState }: StateContext<ServiceCaseResult>) {
    const state: ServiceCaseResult = getState();
    let skipCount: number = state.skipCount + Pagination.skipCount;
    if (skipCount > state.totalCount) {
      skipCount = state.totalCount;
    }
    this.service.getServiceCases(skipCount).subscribe(
      {
        next: (result: ServiceCaseResult) => {
          const tasks = [...state.items, ...result.items];
          const newState = { ...result, items: tasks, skipCount };
          setState(newState);
        }
      })
  }



}
