import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsConfig } from '@ngxs/store/src/symbols';
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AllStates } from './all.state';
import { FeedService } from '../@services/index';

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
    /**
     * Run in development mode. This will add additional debugging features:
     * - Object.freeze on the state and actions to guarantee immutability
     * todo: you need set production mode
     * import { environment } from '@env';
     * developmentMode: !environment.production
     */
    developmentMode: true
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
    /**
     * Whether the dev tools is enabled or note. Useful for setting during production.
     * todo: you need set production mode
     * import { environment } from '@env';
     * disabled: environment.production
     */
    disabled: false
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
    /**
     * Disable the logger. Useful for prod mode..
     * todo: you need set production mode
     * import { environment } from '@env';
     * disabled: environment.production
     */
    disabled: false
};

@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forRoot(
            [
                ...AllStates
            ], OPTIONS_CONFIG),
        NgxsReduxDevtoolsPluginModule.forRoot(DEVTOOLS_REDUX_CONFIG),
        NgxsLoggerPluginModule.forRoot(LOGGER_CONFIG),
        NgxsEmitPluginModule.forRoot(),
        NgxsResetPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
            key: [...AllStates]
        })
    ],
    providers: [
        FeedService
    ],
    exports: [NgxsModule]
})
export class NgxsStoreModule { }