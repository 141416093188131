import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINTS } from '../@config/endpoints';
import { CommentEntry, ServiceCaseEntry, ServiceCasePayload, ServiceCaseResult } from '../@models';
import { Pagination } from '../@config';

@Injectable({
  providedIn: 'root'
})
export class ServiceCasesService {

  constructor(private http: HttpClient) { }

  getServiceCases(skipCount: number): Observable<ServiceCaseResult> {
    return this.http.get<ServiceCaseResult>(ENDPOINTS.getServiceCases(Pagination.maxResultCount, skipCount, '')).pipe(
      map((respone: any) => respone?.result || []));
  }

  getAll(skipCount: number, searchText?: string): Observable<ServiceCaseEntry[]> {
    return this.http.get<ServiceCaseEntry[]>(ENDPOINTS.getServiceCases(Pagination.maxResultCount, skipCount, searchText || '')).pipe(
      map((respone: any) => respone?.result?.items || []));
  }

  getServiceCaseComments(serviceCaseId: number): Observable<ServiceCaseResult> {
    return this.http.get<ServiceCaseResult>(ENDPOINTS.getServiceCaseComments(serviceCaseId)).pipe(
      map((respone: any) => respone?.result || []));
  }

  createOrUpdateServiceCase(payload: ServiceCasePayload): Observable<any> {
    return this.http.post(ENDPOINTS.createOrUpdateServiceCase(), payload);
  }

  createServiceCaseComment(payload: CommentEntry): Observable<any> {
    return this.http.post(ENDPOINTS.createServiceCaseComment(), payload);
  }

  getTenantId():  Observable<any> {
    return this.http.get<any>(ENDPOINTS.getTenantId()).pipe(
      map((respone: any) => respone?.result || []));
  }

}
