import { Selector, State, StateContext } from '@ngxs/store';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { Injectable } from '@angular/core';

export interface AppStateModel {
  userId?: string;
  tenantId?: number;
  contactId?: string;
  token?: string;
}

@State<AppStateModel>({
  name: 'nxMobileApp',
  defaults: {}
})
@Injectable()
export class AppState {

  @Selector()
  static token(state: AppStateModel): string {
    return state.token || '';
  }

  @Selector()
  static tenantId(state: AppStateModel): number {
    return state.tenantId;
  }

  @Selector()
  static userId(state: AppStateModel): string {
    return state.userId || '';
  }

  @Selector()
  static contactId(state: AppStateModel): string {
    return state.contactId || '';
  }


  @Selector()
  static isAuthenticated(state: AppStateModel): boolean {
    return !!state.token;
  }

  @Receiver()
  static setAuthDetails(
    { setState, getState }: StateContext<AppStateModel>,
    { payload }: EmitterAction<AppStateModel>) {
    const newState = { ...getState(), ...payload };
    setState(newState);
  }

  @Receiver()
  static setTenantId(
    { patchState }: StateContext<AppStateModel>,
    { payload }: EmitterAction<number>) {
    patchState({ tenantId: payload });
  }

}
