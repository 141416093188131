import { Selector, State, StateContext } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { ENDPOINTS } from 'src/app/@config';
import { IdNamePair } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';

@State<IdNamePair[]>({
  name: 'incoterms',
  defaults: []
})
@Injectable()
export class IncotermsState {
  private static dropdownService: DropdownService

  @Selector()
  static isEmpty(state: IdNamePair[]): boolean {
    return state.length === 0;
  }

  constructor(injector: Injector) {
    IncotermsState.dropdownService = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<IdNamePair[]>) {
    this.dropdownService.getDropdownData(ENDPOINTS.getIncotermList()).subscribe((data: IdNamePair[]) => setState(data));
  }
}
