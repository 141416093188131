import { State, StateContext, createSelector, Selector } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { QuoteEntry,  QuoteProduct, IdNamePair, ContactEntry, OriginQuoteAddress, CityAndPostalCode, DestinationQuoteAddress, CargoShipment, AditionalService, ColleagueEntry } from '../@models';
import { LeadsResult } from '../@models/leads/lead-entry.model';

export const DefaultQuote: QuoteEntry = {
  contactId: 0,
  customerId: 0,
  quoteStatusId: 1,
  quoteProductType: 2,
  validFrom: new Date(),
  quoteProduct: {
    incotermId: 0,
    productId: 0,
    aditionalService: {},
    cargoShipments: [],
    destinationQuoteAddress: {
      countryId: 0,
      cityId: 0,
      countryPostalCode: ''
    },
    originQuoteAddress: {
      cityId: 0,
      countryId: 0,
      countryPostalCode: ''
    }
  }
}
@State<QuoteEntry>({
  name: 'newQuote',
  defaults: DefaultQuote
})
@Injectable()
export class NewQuoteState {

  @Selector()
  static cargoShipments(state: QuoteEntry): CargoShipment[] {
    return state?.quoteProduct?.cargoShipments;
  }

  static hasAny() {
    return createSelector([NewQuoteState], (state: LeadsResult) => {
      return state.items?.length > 0;
    });
  }

  constructor(injector: Injector) {
  }

  @Receiver()
  static reset({ setState }: StateContext<QuoteEntry>) {
    setState(DefaultQuote)
  }

  @Receiver()
  static setQuoteForEdit({ setState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<QuoteEntry>) {
    setState(payload);
  }

  @Receiver()
  static setProductFamilyId({ patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<number>) {
    patchState({ quoteProductType: payload });
  }

  @Receiver()
  static setQuoteStatusId({ patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<number>) {
    patchState({ quoteStatusId: payload });
  }

  @Receiver()
  static setValidUntil({ patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<Date>) {
    patchState({ 
      validFrom: new Date(),
      validUntil: payload
     });
  }
  

  @Receiver()
  static setProduct({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<IdNamePair>) {
    const quoteProduct: QuoteProduct = {
      ...getState().quoteProduct,
      productId: payload.id,
      name: payload.value
    }
    patchState({ quoteProduct });
  }

  @Receiver()
  static setContact({ patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<ContactEntry>) {

    patchState({
      contactId: payload.id,
      customerId: payload.customerId,
      contactPictureUrl: payload.picture,
      contactName: payload?.lastName + ' ' + payload?.firstName,
      customerName: payload.customerName,
    });
  }

  @Receiver()
  static setOriginCountryId({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<IdNamePair>) {
    const originQuoteAddress: OriginQuoteAddress = {
      ...getState().quoteProduct.originQuoteAddress,
      countryId: payload.id,
      countryName: payload.name,
      cityId: 0,
      cityName: null,
      countryPostalCode: null
    }
    const quoteProduct = {
      ...getState().quoteProduct,
      originQuoteAddress
    };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setOriginPostalCodeCity({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<CityAndPostalCode>) {
    const originQuoteAddress: OriginQuoteAddress =
    {
      ...getState().quoteProduct.originQuoteAddress,
      countryPostalCode: payload.postalCode,
      cityId: payload.cityId,
      cityName: payload.cityName
    };
    const quoteProduct = {
      ...getState().quoteProduct,
      originQuoteAddress
    }
    patchState({ quoteProduct });
  }

  @Receiver()
  static setDestnationCountryId({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<IdNamePair>) {
    const destinationQuoteAddress: DestinationQuoteAddress = {
      ...getState().quoteProduct.destinationQuoteAddress,
      countryId: payload.id,
      countryName: payload.name,
      cityId: 0,
      cityName: null,
      countryPostalCode: null
    }
    const quoteProduct = {
      ...getState().quoteProduct,
      destinationQuoteAddress
    };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setDestinationPostalCodeCity({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<CityAndPostalCode>) {
    const destinationQuoteAddress: DestinationQuoteAddress =
    {
      ...getState().quoteProduct.destinationQuoteAddress,
      countryPostalCode: payload.postalCode,
      cityId: payload.cityId,
      cityName: payload.cityName
    };
    const quoteProduct = {
      ...getState().quoteProduct,
      destinationQuoteAddress
    }
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIncoterm({ getState, patchState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<IdNamePair>) {
    const quoteProduct = {
      ...getState().quoteProduct,
      incotermId: payload.id,
      incotermName: payload.name
    };
    patchState({ quoteProduct });
  }

  @Receiver()
  static addCargo({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<CargoShipment>) {
    const cargoShipments: CargoShipment[] = [...getState().quoteProduct.cargoShipments || []]

    let maxId = 0;
    if (cargoShipments.length > 0) {
      maxId = cargoShipments.reduce(
        (max, cargo) => (cargo.id > max ? cargo.id : max),
        cargoShipments[0].id
      );
    }
    const newCargo: CargoShipment = { ...payload, id: maxId + 1 }
    cargoShipments.push(newCargo);
    const quoteProduct = { ...getState().quoteProduct, cargoShipments };
    patchState({ quoteProduct });
  }

  @Receiver()
  static editCargo({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<CargoShipment>) {
    const cargoShipments = getState().quoteProduct.cargoShipments.filter((entry: CargoShipment) => {
      if (entry.id !== payload.id) return entry;
    })
    cargoShipments.push(payload)
    const quoteProduct = { ...getState().quoteProduct, cargoShipments };
    patchState({ quoteProduct });
  }

  @Receiver()
  static removeCargoItem({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<CargoShipment>) {

    const cargoShipments = getState().quoteProduct.cargoShipments.filter((entry: CargoShipment) => {
      if (entry.id !== payload.id) return entry;
    })
    const quoteProduct = { ...getState().quoteProduct, cargoShipments };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setPickupDate({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<Date>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      pickDateTime: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setDeliveryDate({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<Date>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      deliveryDateTime: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsDangerousGoods({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isDangerousGoodCargo: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsNotification({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isNotification: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsAdditionalUnloadingPoint({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isAdditionalUnloadingPoint: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setAdditionalUnloadingPointNumber({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<number>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      additionalUnloadingPointNumber: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsFloorBasementDelivery({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isFloorBasementDelivery: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsHCDGGood({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isHCDGGood: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsLenghtOverThreeMeter({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isLenghtOverThreeMeter: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsCashonDelivery({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isCashonDelivery: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setCashonDeliveryTotalCharge({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<number>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      cashonDeliveryTotalCharge: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setIsOtherServices({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<boolean>) {
    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      isAdditionalServices: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setOtherServices({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<string>) {

    const aditionalService: AditionalService = {
      ...getState().quoteProduct.aditionalService,
      additionalServiceDetails: payload
    }
    const quoteProduct = { ...getState().quoteProduct, aditionalService };
    patchState({ quoteProduct });
  }

  @Receiver()
  static setColleague({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<ColleagueEntry>) {

    patchState({
      colleagueId: payload.id,
      colleagueName: payload?.lastName + ' ' + payload?.firstName,
      colleaguePictureUrl: payload?.picture
    });
  }

  @Receiver()
  static setAdditionalRemarks({ patchState, getState }: StateContext<QuoteEntry>,
    { payload }: EmitterAction<string>) {
    patchState({
      comment: payload
    });
  }

}
