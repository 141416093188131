import { Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Receiver } from '@ngxs-labs/emitter';
import { IdNamePair } from '../../@models';
import { Injectable, Injector } from '@angular/core';
import { ENDPOINTS } from '../../@config';
import { DropdownService } from '../../@services';

@State<IdNamePair[]>({
  name: 'countries',
  defaults: []
})
@Injectable()
export class CountriesState {
  private static dropdownService: DropdownService

  @Selector()
  static isEmpty(state: IdNamePair[]): boolean {
    return state.length === 0;
  }

  static getNameById(id: number) {
    return createSelector([CountriesState], (state: IdNamePair[]) => {
      return state.find((item: IdNamePair) => item.id === id).name;
    });
  }

  constructor(injector: Injector) {
    CountriesState.dropdownService = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<IdNamePair[]>) {
    this.dropdownService.getDropdownData(ENDPOINTS.getCountryList()).subscribe((data: IdNamePair[]) => setState(data));
  }
}
