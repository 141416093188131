import { createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Injectable, Injector } from '@angular/core';
import { IdNamePair, ProductTypes } from 'src/app/@models';
import { DropdownService } from 'src/app/@services';
import { ENDPOINTS } from 'src/app/@config/endpoints';


@State<IdNamePair[]>({
  name: 'serviceCaseTypes',
  defaults: []
})
@Injectable()
export class ServiceCaseTypesState {

  private static service: DropdownService

  static hasAny() {
    return createSelector([ServiceCaseTypesState], (state: IdNamePair[]) => {
      return state.length > 0;
    });
  }

  static getProductsByType(productType: ProductTypes) {
    return createSelector([ServiceCaseTypesState], (state: IdNamePair[]) => {
      return state;
    });
  }

  constructor(injector: Injector) {
    ServiceCaseTypesState.service = injector.get(DropdownService);
  }

  @Receiver()
  static loadAll({ setState }: StateContext<ServiceCaseTypesState>) {
    this.service.getDropdownData(ENDPOINTS.getServiceCaseTypes()).subscribe((products: IdNamePair[]) => {
      setState(products);
    })
  }

}
